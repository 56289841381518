<template>
  <div class="header user-login" x-data="header" :class="{ 'menu-open': menu.open }">
    <!-- <div class="top-note">Send it - The UAE’s #1 Shipping Comparison Site</div> -->
    <div class="top-nav-wrapper">
      <div class="container">
        <nav class="main-nav">
          <div class="logo-wrapper">
            <a :href="marketingSiteUrl" class="logo">
              <img src="../assets/logo.svg" alt="" />
            </a>
            <a href="#" class="hamburger" @click.prevent="toggleMenu()"></a>
          </div>

          <div class="main-links-wrapper">
           <!-- 
            <div class="main-links">
              <a :href="marketingSiteUrl + 'about-us'">About</a>
              <a :href="marketingSiteUrl + 'destination'">Our Destinations</a>
              <a :href="marketingSiteUrl + 'our-services'">Our Services</a>
              <a :href="marketingSiteUrl + 'faq'">FAQs</a>
              <a :href="marketingSiteUrl + 'contact'">Contact</a>
            </div>
            -->

              <div class="main-links">
              <a :href="marketingSiteUrl + 'our-services'">Our Services</a>
              <a :href="marketingSiteUrl + 'about-us'">About Us</a>
              <a :href="marketingSiteUrl + 'faq'">FAQs</a>
              <a :href="marketingSiteUrl + 'contact'">Contact</a>
            </div>

            <div class="account">
              <div v-if="!$store.getters.loggedIn">
                <router-link to="/login" class="trigger">Account</router-link>
              </div>

              <!-- <div v-if="$store.getters.loggedIn">
                <router-link to="/settings" class="has-name trigger">
                <div class="dp" :style="{ 'background-image': 'url(' + $store.state.user.profileImage + ')' }"></div>
                <div class="name">{{ $store.state.user.firstName }}</div>
                </router-link>
              </div> -->

              <div v-if="$store.getters.loggedIn">
                <a href="#" class="trigger has-dp">
                  <div class="dp" :style="{ 'background-image': $store.state.user.profileImage != null ? 'url(' + $store.state.user.profileImage + ')':'' }"></div>
                </a>

                <div class="dropdown">
                  <div class="inner-content">
                    <router-link class="orders" to="/orders">Orders</router-link>
                    <router-link class="transactions" to="/transactions">Transaction</router-link>
                    <router-link class="addresses" to="/addresses">Addresses</router-link>
                    <router-link class="settings" to="/settings">Settings</router-link>
                    <div class="sep"></div>
                    <router-link class="logout" to="/logout">Logout</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
/* global disableBodyScrolling, enableBodyScrolling */
export default {
  name: 'Header',
  data() {
    return {
      marketingSiteUrl: process.env.VUE_APP_MARKETING_SITE_URL,
      menu: {
        open: false,
      },
    };
  },
  mounted() {

    window.addEventListener('toggleMenu', (e) => {
      this.toggleMenu(e.detail.open);
    });

  },
  methods: {
    toggleMenu(open) {
      open = typeof open != 'undefined' ? open : !this.menu.open;
      this.menu.open = open;
      
      if (this.menu.open) {  
        setTimeout(() => {
          disableBodyScrolling();
        });
      } else {
        enableBodyScrolling();
      }
    },
  },
};
</script>
