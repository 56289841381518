<template>
  <!-- @click.away="expand = false" -->
  <div class="tracking-form" x-data="trackingForm" :class="[formState, expand ? 'expand' : '']" @click="expand = true">
    <div class="result" v-show="hasResult">
      <div class="locations">
        <div class="from">{{ from }}</div>
        <div class="arrow"></div>
        <div class="to">{{ to }}</div>
      </div>
      <div class="events" v-show="events.length">
        <div v-for="(event, index) in events" class="event" :class="['event-' + index, events.length - 1 == index ? 'last' : '']" :key="index">
          <div class="dot"></div>
          <div class="time">{{ event.time }}</div>
          <div class="description">
            <span>{{ event.location }}</span
            >,
            <span>{{ event.description }}</span>
          </div>
        </div>
      </div>
      <div class="no-events" v-show="!events.length">
        ...
      </div>
    </div>
    <div class="no-result" v-show="!hasResult">
      Order not found.
    </div>
    <form action="" @submit="submit" novalidate>
      <div class="icon"></div>
      <input type="text" placeholder="Tracking Number..." v-model="trackingNumber" @focus="trackingNumberOnFocus" @blur="trackingNumberOnBlur"/>
      <div class="label">Track my<br />shipment</div>
      <input type="submit" v-show="trackingNumber != '' && trackingNumberInFocus" />
      <a href="#" class="close" @click.prevent="close()" v-show="trackingNumber == '' || !trackingNumberInFocus"></a>
      <div class="loader"></div>
    </form>
  </div>
</template>

<script>
/* global enableBodyScrolling, disableBodyScrolling */
export default {
  name: 'TrackingForm',
  data() {
    return {
      formState: null,
      hasResult: false,
      expand: false,
      from: '',
      to: '',
      events: [],
      trackingNumber: '',
      trackingNumberInFocus: false,
    };
  },
  methods: {
    submit(e) {
      e.preventDefault();

      this.formState = 'loading';

      this.$store
        .dispatch('trackOrder', { orderId: this.trackingNumber })
        .then((data) => {
          this.from = data.from;
          this.to = data.to;
          this.events = data.events;
          this.hasResult = true;
          this.formState = 'success';
          disableBodyScrolling();
        })
        .catch(() => {
          this.formState = 'success';
          this.hasResult = false;
          disableBodyScrolling();
        });
    },
    close() {
      this.formState = null;
      setTimeout(() => {
        this.expand = false;
      }, 100);
      enableBodyScrolling();
    },
    trackingNumberOnFocus() {
      this.trackingNumberInFocus = true;
    },
    trackingNumberOnBlur() {
      setTimeout(() => {
        this.trackingNumberInFocus = false;
      }, 100);
    },
  },
};
</script>
