<template>
  <div class="footer">
    <div class="footer-nav-wrapper">
      <div class="container">
        <nav class="footer-nav">
          <div class="footer-links">
            <a :href="marketingSiteURL + 'terms-conditions'">Terms and Condition</a>
            <a :href="marketingSiteURL + 'privacy-policy'">Privacy Policy</a>
            <a :href="marketingSiteURL + 'faq'">FAQ</a>
            <a :href="marketingSiteURL + 'contact'">Contact</a>

            <div class="pay">
                <img src="../assets/visa.svg">
                <img src="../assets/mastercard.svg">
                <img src="../assets/unionpay.svg">
            </div>

            <div class="credits">Website by <a href="https://www.bluebeetle.ae/" title="Web Design Company Dubai" target="_blank">Blue Beetle</a></div>

          </div>
        </nav>
      </div>
    </div>

    <!-- <div class="footer-gradient-wrapper">
      <div class="footer-gradient"></div>
    </div> -->
  </div>

  <div class="tracking-n-chat">
    <div class="container">
      <div class="inner-wrapper">
        <TrackingForm />

        <div class="chat-wrapper">
          <a href="#" class="chat"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global debounce */

import TrackingForm from '@/components/TrackingForm.vue';

export default {
  name: 'Footer',
  components: {
    TrackingForm,
  },
  data() {
    return {
      marketingSiteURL: process.env.VUE_APP_MARKETING_SITE_URL,
      trackingForm: {
        expand: false,
        trackingNumber: '',
      },
    };
  },
  mounted() {
    this.stickyTrackingNChat();
    window.addEventListener('scroll', debounce(this.stickyTrackingNChat, 100));
  },
  methods: {
    stickyTrackingNChat() {
      if (window.innerWidth <= 640) {
        if (document.documentElement.scrollTop >= 100) {
          document.querySelector('.tracking-n-chat').classList.add('show');
        } else {
          if (!document.querySelector('.tracking-form').classList.contains('success')) {
            document.querySelector('.tracking-n-chat').classList.remove('show');
          }
        }
      } else {
        document.querySelector('.tracking-n-chat').classList.add('show');
      }
    },
  },
};
</script>
