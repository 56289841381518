<template>
  <div class="quote-n-book-widget-container"></div>
</template>

<script>
export default {
  props: {
    expanded: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    window.qnbwe = this.expanded;
    window.userApp = true;
    //f = document.getElementsByTagName('script')[0],
    var j = document.createElement('script');
    j.src = process.env.VUE_APP_MARKETING_CDN_SITE_URL + 'quote-n-book/widget.js.php?ver=' + Date.now();
    j.async = true;
    //f.parentNode.insertBefore(j, f);
    document.head.appendChild(j);
  },
};
</script>
